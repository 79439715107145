import * as React from 'react';
import Helmet from 'react-helmet';

const Meta = ({ titleSuffix }) => {
  return (
    <Helmet>
      <title>{`Whispr | ${titleSuffix || 'What do your friends think'}`}</title>
      <link
        rel="icon"
        type="image/png"
        href={'/whispr-favicon.webp'}
        sizes="16x16"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      />
      <meta charset="UTF-8" />
      <meta property="og:image" content="http://www.swipr.cc/og_image.jpg" />
      <meta
        property="og:title"
        content={`Whispr | ${titleSuffix || 'What do your friends think'}`}
      />
      <meta
        property="og:description"
        content="Whispr - What do your friends think"
      />
      <meta name="description" content="Whispr - What do your friends think" />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6288985965837099"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  );
};

export default Meta;
