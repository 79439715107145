import * as React from 'react';
import BlackHeader from 'components/whispr/BlackHeader';
import DescFooter from 'components/DescFooter';
import Meta from 'components/whispr/Meta';

import 'styles/index.scss';

export default () => {
  return (
    <>
      <Meta titleSuffix={'Privacy Policy'} />
      <main className={'desc'}>
        <BlackHeader text={'Privacy Policy'} />
        <div className={'desc-content'}>
          <p>
            <strong>I. Important introduction to our privacy policy</strong>
            <p>Swipr is committed to protecting and respecting your privacy.</p>
            This Privacy Policy explains how Swipr Inc. (“Swipr” “we”, “our”, or
            “us”), the company that operates Whispr, collects, uses, shares, and
            stores your information when you use the Whispr website and/or the
            mobile applications that link to this Privacy Policy (“Services”).
            When you use our Services, you are agreeing to our rules and
            policies, including this Privacy Policy and Terms of Use, and you
            consent to our collecting, processing, and storing your information
            as described below. You must not access or use the Services if you
            do not agree with our Privacy Policy. You should also read our Terms
            of Service because they also apply to your use of the Services.
          </p>
          <p>
            <strong>II. Information we collect</strong>
            <br />
            There are three basic categories of information we collect:
          </p>
          <ul>
            <li>Information you provide to us.</li>
            <li>Information we get when you use our Services.</li>
            <li>Information we get from third parties.</li>
          </ul>
          Here’s a little more detail on each of these categories.
          <br />
          <p>
            <strong>Information You Provide to Us</strong>
            <p>
              We collect information that you provide directly to us, including
              when you register for an account, update your e-mail preferences,
              respond to a survey or provide other feedback about the App, or
              contact us with questions or comments about the App.
            </p>
            <p>
              We may also collect information about you when you opt-in to
              receive text messages from us (for example, when you sign up for
              Whispr). You may opt-in to receive such updates and offers by
              providing your mobile telephone number through the App.
            </p>
            <p>
              We will not ask for or request sensitive information such as
              government identifiers, medical information, or financial
              information. Please do not provide this information to us through
              e-mails, feedback forms, or in any other way unless specifically
              requested.
            </p>
          </p>
          <p>
            <strong>Information We Get When You Use Our Services</strong>
            <br />
            When you use our Services, we collect information about which of
            those Services you’ve used and how you’ve used them. Here’s a fuller
            explanation of the types of information we may collect when you use
            our Services:
          </p>
          <ul>
            <li>
              <strong>Usage Information.</strong> We collect information about
              your activity through our Services. For example, we may collect
              information about:
            </li>
            <ul>
              <li>
                how you interact with our Services, such as which pages you view
                the most, how often you use our Services, etc.
              </li>
              <li>
                how you communicate with other users on Swipr, such as their
                names, the time and date of your communications, the number of
                messages you exchange with your friends, which friends you
                exchange messages with the most, and your interactions with
                messages (such as when you open a message or capture a
                screenshot).
              </li>
            </ul>
            <li>
              <strong>Content Information.</strong> We collect content you
              create on our Services and information about the content you
              create or provide, such as if the recipient has viewed the content
              and the metadata that is provided with the content.
            </li>
            <li>
              <strong>Device Information.</strong> We collect information from
              and about the devices you use.
            </li>
            <li>
              <strong>Device Phonebook.</strong> We —with your
              permission—collect information from your device’s phonebook.
            </li>
            <li>
              <strong>Camera and Photos.</strong> Some of our Services require
              us to collect images and other information from your device’s
              camera and photos.
            </li>
            <li>
              <strong>Location Information.</strong> When you use our Services
              we may collect information about your location with your
              permission. With your permission, we may also collect information
              about your precise location using methods that include GPS,
              wireless networks, cell towers, Wi-Fi access points, and other
              sensors, such as gyroscopes, accelerometers, and compasses.
            </li>
            <li>
              <strong>
                Information Collected by Cookies and Other Technologies.
              </strong>{' '}
              Like most online services and mobile applications, we may use
              cookies and other technologies, such as web beacons, web storage,
              and unique advertising identifiers, to collect information about
              your activity, browser, and device. Most web browsers are set to
              accept cookies by default. If you prefer, you can usually remove
              or reject browser cookies through the settings on your browser or
              device. Keep in mind, though, that removing or rejecting cookies
              could affect the availability and functionality of our services.
            </li>
            <li>
              <strong>Log Information.</strong> We also collect log information
              when you use our website. That information includes, among other
              things:
            </li>
            <ul>
              <li>details about how you’ve used our services.</li>
              <li>
                device information, such as your web browser type and language.
              </li>
              <li>access times.</li>
              <li>pages viewed.</li>
              <li>
                IP address. identifiers associated with cookies or other
                technologies that may uniquely
              </li>
              <li>identify your device or browser.</li>
              <li>
                pages you visited before or after navigating to our website.
              </li>
            </ul>
            <li>
              <strong>In App Purchase.</strong> When you subscribe to a paid
              service or make any other type of purchase or make a purchase
              directly from us (rather than through a platform such as iOS or
              Android), you provide us or our payment information.
            </li>
          </ul>
          <p>
            Keeping your information private and secure is of our utmost
            importance. We take deep measures to ensure that we only collect
            information that you explicitly give us permission to. We will never
            try to trick you into giving us information nor will we abuse the
            information you give us. While we collect information (such as your
            Contacts, Location, etc.) to help you take full advantage of our
            product features, we understand if you feel uncomfortable giving us
            permission. If so, you are encouraged to deny giving us permission.
            You also have the ability to delete any information you have given
            us from our servers; for example, if you have given us permission to
            your Contacts, but no longer wish to have them stored in our
            servers, let us know at whispr-support@swipr.cc. You can also delete
            your account or any other information from our servers by reaching
            us at that email.
          </p>
          <p>
            <strong>Information We Collect from Third Parties</strong>
            <br />
            We may collect information about you from other users and third
            parties. For example, if another user uploads their contact list, we
            may combine information from that user’s contact list with other
            information we have collected about you.
            <p>
              <strong>Snapchat Integration</strong>
            </p>
            <p>
              You may choose to connect to us through Snapchat, and when you do,
              we may collect additional information from you, such as your
              display name and Bitmoji, through Snapchat. Please be advised that
              social media platforms may also collect information from you. We
              do not have control over the collection, use and sharing practices
              of social media platforms and encourage you to review their usage
              and disclosure policies and practices, including the data security
              practices, before using the social media platforms.
            </p>
          </p>
          <p>
            <strong>III. How we use information</strong>
            <br />
            <strong>Personally Identifiable Information</strong>
            <p>
              Some of the information we collect through your use of our App or
              communications with us may personally identify you (“Personally
              Identifiable Information”). The types of Personally Identifiable
              Information you may submit in connection with the use of the App
              include:
            </p>
          </p>
          <ul>
            <li>Snapchat display name;</li>
            <li>Snapchat Bitmoji;</li>
            <li>geographic location.</li>
          </ul>
          <p>
            Other data derived from your use of the App is treated as "
            <strong>Non-Personally Identifiable Information</strong>," unless it
            is combined with Personally Identifiable Information, or unless
            otherwise required by applicable law.
          </p>
          <p>
            We may use the Personally Identifiable Information we collect, to:
          </p>
          <ul>
            <li>create and manage your account;</li>
            <li>provide the App to you;</li>
            <li>
              operate our App, including access management, App administration,
              internal operations, troubleshooting, data analysis, testing,
              research, statistical and survey purposes;
            </li>
            <li>send you information that enables you to use our App;</li>
            <li>contact you about activity on your account;</li>
            <li>
              provide you access to, and updates regarding the App and other
              related offers via text message;
            </li>
            <li>respond to your requests, feedback, or inquiries;</li>
            <li>
              notify you about updates, information, or alerts regarding our
              App;
            </li>
            <li>
              comply with laws, regulations, and other legal requirements;
            </li>
            <li>comply with relevant industry standards and our policies;</li>
            <li>
              protect and enforce our rights and the rights of other Users
              against unlawful activities, including identify theft and fraud,
              and other violations of our Terms of Use;
            </li>
            <li>
              protect and enforce our rights arising under any agreements
              entered into between you and us;
            </li>
            <li>
              protect the integrity and maintain the security of our App,
              including secured areas of the App;
            </li>
            <li>
              operate, evaluate and improve our business, including conducting
              surveys and market research, developing new products, services,
              and promotions (such as, for example, special events, programs,
              offers, contests), analyzing and enhancing existing products,
              services, and promotions, managing our communications; performing
              accounting, auditing, and other internal functions;
            </li>
            <li>
              provide you with information and advertisements about products,
              services, and promotions that may interest you; and
            </li>
            <li>
              administer your participation in such products, services, and
              promotions.
            </li>
          </ul>
          <p>
            In addition, we may use your information as described in any notice
            provided at the time you provide the information; and for any other
            purpose for which you may provide consent.
          </p>
          <strong>Non-Personally Identifiable Information</strong>
          <p>
            In addition to the uses described above, we may also use
            Non-Personally Identifiable Information to: deliver content tailored
            to your interests and the manner in which you use App and present
            content in a manner that is optimized for your device.
          </p>
          <p>
            We may also combine technical information, or Non-Personally
            Identifiable Information, about your use of our App with information
            that we obtain from other Users to use in an aggregate or anonymous
            manner for similar purposes.
          </p>
          <p>
            <strong>IV. How we share information</strong>
            <p>
              <strong>Personally Identifiable Information</strong>
            </p>
            <p>
              We will not sell or share your Personally Identifiable Information
              with third parties for the third party's own direct marketing
              purposes without your express consent.
            </p>
            <p>We may share Personally Identifiable Information with:</p>
          </p>
          <ul>
            <li>
              our service providers to the extent reasonably necessary to enable
              us to operate our business and provide our App to you, as
              described in this Privacy Policy (e.g., to an e-mail service
              provider in order to enable us to e-mail you);
            </li>
            <li>
              a buyer or other successor in the event of a merger, divestiture,
              restructuring, reorganization, dissolution, liquidation, or other
              sale or transfer of some or all of our assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which Personally Identifiable Information held by
              us about our Users is among the assets transferred;
            </li>
            <li>
              other third parties with your express consent for any purpose
              disclosed by us when you provide the information; and
            </li>
            <li>you, upon your written request.</li>
          </ul>
          <p>
            We do not permit these third parties to use any Personally
            Identifiable Information we share for any purpose other than (i) as
            described in this Privacy Policy, and (ii) to comply with their own
            legal requirements.
          </p>
          <p>
            We may also disclose Personally Identifiable Information with law
            enforcement agencies, government officials, or other third parties
            as necessary for the purpose of:
          </p>
          <ul>
            <li>
              complying with any court order, law, or legal process, including
              to respond to any government or regulatory request;
            </li>
            <li>preventing fraud and providing credit risk reduction;</li>
            <li>
              investigating potential unauthorized access or misuse of our App
              or other breaches of our Terms of Use, Supplemental Terms (as
              defined below), App Rules (as defined below) other agreements;
            </li>
            <li>
              protecting the assets or property, and enforcing the rights of
              Swipr, including for billing and collection purposes; and
            </li>
            <li>
              protecting the rights, property, or safety of our Users or others.
            </li>
          </ul>
          <p>
            In the event that we receive a request from a governmental entity to
            provide it with your Personally Identifiable Information, we will
            make reasonable attempts to notify you of such request, to the
            extent reasonably possible and legally permissible.
          </p>
          <strong>
            Notice to California Residents / Your California Privacy Rights
          </strong>
          <p>
            California Civil Code permits California residents to request that
            we not share your Personally Identifiable Information with third
            parties for their direct marketing purposes. If you are a California
            resident, you may contact us at whispr-support@swipr.cc to request
            information regarding how we share Personally Identifiable
            Information with third parties for their direct marketing purposes
            and/or to request that such information not be shared with third
            parties for such purposes.
          </p>
          <p>
            <strong>V. Third-party Content and Integrations</strong>
            <br />
            Our services may also contain third-party links and search results,
            include third-party integrations, or offer a co-branded or
            third-party-branded service. Through these links, third-party
            integrations, and co-branded or third-party-branded services, you
            may be providing information (including personal information)
            directly to the third party, us, or both. You acknowledge and agree
            that we are not responsible for how those third parties collect or
            use your information. As always, we encourage you to review the
            privacy policies of every third-party service that you visit or use,
            including those third parties you interact with through our
            services.
          </p>
          <p>
            <strong>VI. Cookies and Beacons</strong>
            <br />
            We may use cookies, beacons, and similar technologies, now or in the
            future, to support the functionality of our App. This provides a
            better experience when you visit our App and allows us to improve
            our App. Our service providers may use cookies and beacons to
            collect and share Non-Personally Identifiable Information about your
            activities both on our App and on other websites. In addition, third
            parties that are unaffiliated with us may also collect information
            about you, including tracking your browsing history, when you use
            our App. We do not have control over these third-party collection
            practices. If you wish to minimize these third-party collections,
            you can adjust the settings of your browsers or install plug-ins and
            add-ins.
            <ul>
              <li>
                <strong>Browser Cookies.</strong> A browser cookie is a small
                file placed on the hard drive of your computer. That cookie then
                communicates with servers, ours or those of other companies that
                we authorize to collect data for us, and allows recognition of
                your personal computer. We do not collect Personally
                Identifiable Information from browser cookies and we do not
                associate browser cookies with your Personally Identifiable
                Information. You may use the tools available on your computer or
                other devices to set your browser to refuse or disable all or
                some browser cookies, or to alert you when cookies are being
                sent. However, if you refuse or disable all browser cookies, you
                may be unable to access certain parts or use certain features or
                functionality of our App. Unless you have adjusted your browser
                settings so that it refuses all cookies, we may use cookies when
                you direct your browser to our App. To learn more about cookies,
                you can visit http://www.allaboutcookies.org.
              </li>
              <li>
                <strong>Flash Cookies.</strong> Certain features of our App may
                use local stored objects called flash cookies to collect and
                store information about your preferences and navigation to,
                from, and on our App. The cookies do not identify you as an
                individual or track your online behavior. We do not collect
                Personally Identifiable Information from flash cookies and we
                will not associate them with your Personally Identifiable
                Information. Flash cookies are not managed by the same browser
                settings as are used for browser cookies. To learn how you can
                manage your Flash cookie settings, visit the Flash player
                settings page on Adobe’s website. If you disable or refuse Flash
                cookies, please note that some parts of our App may be
                inaccessible or may not function properly.
              </li>
              <li>
                <strong>Beacons.</strong> Our App and e-mails may contain small
                electronic files known as beacons (also referred to as web
                beacons, clear GIFs, pixel tags, and single-pixel GIFs) that
                permit us to, for example, count Users who have visited those
                pages or opened an e-mail and for other website-related
                statistics. Beacons in e-mail marketing campaigns allow us to
                track your responses and your interests in our content,
                offerings, and web pages. You may use the tools in your device
                to disable these technologies as well.
              </li>
            </ul>
          </p>
          <p>
            <strong>VII. Securing Your Information</strong>
            <p>
              The security of your information is important to Swipr, and we
              have established administrative, technical, and physical
              safeguards designed to protect your Personally Identifiable
              Information against unauthorized alteration, access, loss, theft,
              use, or disclosure. Unfortunately, no system can guarantee the
              complete security of your information. As a result, Swipr cannot
              ensure or warrant that your information, including your Personally
              Identifiable Information, is secure from unauthorized third
              parties. Thus, your use of the App and communication with us about
              them is at your own risk.
            </p>
            <p>
              You are responsible for protecting your username and password(s)
              and for the security of information that you transmit to us over
              the internet.
            </p>
            <p>
              If you have reason to believe that your interaction with the App
              is no longer secure (for example, if you feel that the security of
              your username or password has been compromised), you must
              immediately notify us of the problem by contacting us.
            </p>
          </p>
          <p>
            <strong>VIII. Children</strong>
            <p>
              Our App is directed to and is intended to be used only by persons
              who are 17 years of age or older. We do not knowingly collect
              information from children under 18. If you are under 17 years of
              age, you are not permitted to register for an account or otherwise
              submit any Personally Identifiable Information to us, including
              your name, address, or e-mail address. If we discover that we have
              collected any Personally Identifiable Information from a child
              under the age of 17, we will suspend the associated account and
              remove that information from our database as soon as possible. By
              registering for an account or submitting any Personally
              Identifiable Information to us, you represent and warrant that you
              are 17 years of age or older.
            </p>
          </p>
          <p>
            <strong>IX. Links to Third-Party Websites</strong>
            <p>
              Our App may contain links to third-party websites and services,
              including those of our partner networks. Please note that these
              links are provided for your convenience and information, and the
              websites and services may operate independently from us and have
              their own privacy policies or notices, which we strongly suggest
              you review. This Privacy Policy applies to Swipr and our App only.
              We do not accept any responsibility or liability for the policies
              or practices of any third parties. If you chose to access any
              websites or services from our App, please check the applicable
              policies before you use or submit any personal data to such
              website or service.
            </p>
          </p>
          <p>
            <strong>IX. Changes to Our Privacy Policy</strong>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </p>
          <p>
            <strong>X. Contact Us</strong>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us by email: whispr-support@swipr.cc{' '}
            </p>
          </p>
          <br />
          <br />
          <br />
        </div>
        <DescFooter />
      </main>
    </>
  );
};
