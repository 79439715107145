import React, { useState } from 'react';

import instagram from 'images/instagram_white.webp';
import menu from 'images/menu_white.webp';

const MobileMenu = ({ show, onMobileMenuClick }) => {
  if (!show) {
    return <></>;
  }
  return (
    <div className={'mobile-menu-container'}>
      <div className={'top'}>
        <div className={'logo'}>
          <a href="/whispr">
            <img src={'/whispr-logo-dark.webp'} width={98} height={30} />
          </a>
        </div>
        <div className={'mobile-menu'}>
          <a href="#" onClick={onMobileMenuClick}>
            <img src={menu} width={32} height={32} />
          </a>
        </div>
      </div>
      <div className={'mobile-menu-container_item-container'}>
        <div className={'mobile-menu-container_item-container_group'}>
          <div className={'item'}>
            <a href="/whispr/terms">Terms</a>
          </div>
          <div className={'item'}>
            <a href="/whispr/privacy-policy">Privacy</a>
          </div>
          <div className={'item'}>
            <a href="mailto:support@swipr.cc">Contact us</a>
          </div>
          {/* <div className={'item icon ig'}>
            <a href="https://www.instagram.com/swipr_official/" target="_blank">
              <img src={instagram} width={22.4} height={22.4} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
